<template>
    <section class="articles-list">
        <div class="content">
            <PrismicRichText :field="slice.primary.title" class="title" />
            <PrismicRichText :field="slice.primary.headline" class="headline" />
        </div>
        <div class="articles">
            <div class="content">
                <div class="articles-grid">
                    <ArticleCard v-for="article in articles" :key="article.uid" :article="article" />
                </div>
                <div class="button-row">
                    <NuxtLink v-slot="{href, navigate}" to="/resources/articles" custom>
                        <VJoyButton variant="main" :href="href" @click="navigate">{{ t('resources.articles.seeAll') }}</VJoyButton>
                    </NuxtLink>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {PrismicRichText} from '@prismicio/vue';
    import type {ArticleDocument, ArticleDocumentData, ArticlesListSliceDefault} from '@prismicio-types';
    import type {FilledContentRelationshipField} from '@prismicio/types';
    import {isFilled} from '@prismicio/helpers';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {useAsyncData, useTranslation} from '#imports';
    import usePrismicSearch from '~/composables/content/usePrismicSearch';
    import ArticleCard from '~/components/ArticleCard.vue';

    const props = defineProps<{slice: ArticlesListSliceDefault}>();

    const articles = [props.slice.primary.article1, props.slice.primary.article2, props.slice.primary.article3].filter((article) =>
        isFilled.contentRelationship<'article', string, ArticleDocumentData>(article),
    ) as (FilledContentRelationshipField<'article', string, ArticleDocumentData> | ArticleDocument)[];

    const {t} = useTranslation();

    function fetchArticles() {
        if (articles.length < 3) {
            // prismic does not allow 'or' queries, so we have to do multiple queries
            const searchQueries = props.slice.items
                .filter((searchFilter) => searchFilter.categoryFilter || searchFilter.tagFilters)
                .map((item) =>
                    usePrismicSearch({
                        type: 'article',
                        pageSize: 3 - articles.length,
                        category: item.categoryFilter ?? undefined,
                        tags: item.tagFilters?.split(', '),
                        excludes: articles.map((article) => article.id),
                    }),
                );
            if (searchQueries.length === 0) {
                searchQueries.push(
                    usePrismicSearch({
                        type: 'article',
                        pageSize: 3 - articles.length,
                        excludes: articles.map((article) => article.id),
                    }),
                );
            }

            return Promise.all(searchQueries);
        }
        return Promise.resolve([]);
    }

    const {data: complementaryArticles} = await useAsyncData('complementary-articles', fetchArticles);

    if (complementaryArticles.value) {
        const mostRecentComplementaryArticles = complementaryArticles.value
            .map((query) => query?.results)
            .flat()
            .filter((article) => article !== undefined)
            .sort((a, b) => new Date(b?.data?.releaseDate).getTime() - new Date(a?.data?.releaseDate).getTime())
            .slice(0, 3 - articles.length) as ArticleDocument[];
        articles.push(...mostRecentComplementaryArticles);
    }
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .articles-list {
        background-color: var(--joy-color-quaternary-10);
        position: relative;
        padding: 150px 0px;
        @media (max-width: $mobile-breakpoint) {
            padding: 80px 0px;
        }
        .category-pill a:not(:hover)::after {
            background-color: var(--joy-color-quaternary-10);
        }
        .articles {
            position: relative;
            &::after {
                content: '';
                background-image: url('~/assets/svg/rainbow.svg');
                background-position: top;
                background-repeat: no-repeat;
                position: absolute;
                width: 100%;
                max-width: 100%;
                left: 50%;
                transform: translateX(-50%);
                top: -80px;
                height: 310px;
                z-index: 0;
                @media (max-width: $mobile-breakpoint) {
                    display: none;
                }
            }
            .articles-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: var(--joy-core-spacing-9);
                justify-items: stretch;
                position: static;
                z-index: 1;
                position: relative;
                overflow: show;

                @media (max-width: $mobile-breakpoint) {
                    grid-template-columns: 1fr;
                }
            }
        }
        .content {
            @include content-width;
            .title p {
                text-align: center;
                color: var(--joy-color-primary-50);
                font-family: var(--joy-font-family-title);
                font-size: var(--joy-font-size-secondary-200);
                margin-bottom: var(--joy-core-spacing-5);
            }
            .headline {
                text-align: center;
                margin-bottom: var(--joy-core-spacing-11);
            }
            .button-row {
                display: flex;
                justify-content: center;
                margin-top: 80px;
                @media (max-width: $mobile-breakpoint) {
                    margin-top: var(joy-core-spacing-12);
                }
            }
        }
    }
</style>
